<template>
  <div class="step1">
    <h2 class="text-center my-3 my-sm-5">Sign Up</h2>
    <b-form
      @submit.prevent="nextForm"
      class="w-100 needs-validation"
      novalidate
    >
      <div class="row mb-5">
        <div class="col-12 col-md-6">
          <div class="container">
            <b-form-group id="input-group-1" label="Title:" label-for="input-1">
              <div class="inputField">
                <b-form-input
                  id="input-1"
                  v-model="form.title"
                  aria-describedby="input-1-live-feedback"
                  :state="validateState('title')"
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
            <div class="row">
              <div class="col-12 col-sm-6">
                <b-form-group
                  id="input-group-2"
                  label="First Name:"
                  label-for="input-2"
                >
                  <div class="inputField">
                    <b-form-input
                      id="input-2"
                      v-model="form.fname"
                      aria-describedby="input-2-live-feedback"
                      :state="validateState('fname')"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-2-live-feedback"
                      >This field is required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-sm-6">
                <b-form-group
                  id="input-group-3"
                  label="Last Name:"
                  label-for="input-3"
                >
                  <div class="inputField">
                    <b-form-input
                      id="input-3"
                      v-model="form.lname"
                      aria-describedby="input-3-live-feedback"
                      :state="validateState('lname')"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-3-live-feedback"
                      >This field is required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </div>
            </div>
            <b-form-group
              id="input-group-4"
              label="Place of Birth:"
              label-for="input-4"
              class="mt-2"
            >
              <div class="inputField">
                <b-form-input
                  id="input-4"
                  v-model="form.birthCity"
                  placeholder="City"
                  aria-describedby="input-4-live-feedback"
                  :state="validateState('birthCity')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-4-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>

            <div class="row">
              <div class="col-12 col-sm-6">
                <b-form-group id="input-group-5" class="mt-2">
                  <div class="inputField">
                    <b-form-input
                      id="input-5"
                      v-model="form.birthState"
                      placeholder="State"
                      aria-describedby="input-5-live-feedback"
                      :state="validateState('birthState')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-5-live-feedback"
                      >This field is required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-sm-6">
                <b-form-group id="input-group-6" class="mt-2">
                  <div class="inputField">
                    <b-form-input
                      id="input-6"
                      v-model="form.birthCountry"
                      placeholder="Country"
                      aria-describedby="input-6-live-feedback"
                      :state="validateState('birthCountry')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-6-live-feedback"
                      >This field is required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="container">
            <div class="datepickerContainer mb-4">
              <label for="datepicker-dateformat2">Date of Birth (DOB)</label>
              <b-form-datepicker
                id="datepicker-dateformat2"
                v-model="form.DOB"
                placeholder="DD/MM/YYYY"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="en-uk"
                aria-describedby="input-7-live-feedback"
                :state="validateState('DOB')"
                required
              ></b-form-datepicker>
              <b-form-invalid-feedback id="input-7-live-feedback"
                >This field is required</b-form-invalid-feedback
              >
            </div>
            <b-form-group
              id="input-group-7"
              label="Citizenship:"
              label-for="input-7"
            >
              <div class="inputField">
                <b-form-input
                  id="input-7"
                  v-model="form.citizenship"
                  aria-describedby="input-8-live-feedback"
                  :state="validateState('citizenship')"
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="input-8-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
            <b-form-group
              id="input-group-8"
              label="Ethnicity:"
              label-for="input-8"
            >
              <div class="inputField">
                <b-form-input
                  id="input-8"
                  v-model="form.ethnicity"
                  aria-describedby="input-9-live-feedback"
                  :state="validateState('ethnicity')"
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="input-9-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="nextBtn w-100 mt-4 mb-3"
            >
              Next
            </b-button>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import axios from "axios";

export default {
  mixins: [validationMixin],

  data() {
    return {
      form: {
        title: "",
        fname: "",
        lname: "",
        birthCity: "",
        birthState: "",
        birthCountry: "",
        DOB: "",
        citizenship: "",
        ethnicity: "",
      },

    };
  },
  validations: {
    form: {
      title: {
        required,
      },
      fname: {
        required,
      },
      lname: {
        required,
      },
      birthCity: {},
      birthState: {},
      birthCountry: {},
      DOB: {},
      citizenship: {},
      ethnicity: {},
    },
  },
  methods: {
    submit() {},
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    nextForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      var storeData = this.$store.getters.getData;
      // console.log(storeData);
      var step1Det = {
        id: storeData.id,
        mobileNo: storeData.mobileNo,
        title: this.form.title,
        firstname: this.form.fname,
        lastname: this.form.lname,
        citizenship: this.form.citizenship,
        dateOfBirth: this.form.DOB,
        step1Complete: true,
        twilioUserId: storeData.twilioUserId,
      };
      this.$store.dispatch("setData", step1Det);
      // console.log(step1Det);
      axios
        .put("https://api.vaxtraxglobal.com/api/v1/user", step1Det, {
          headers: { role: "user" },
        })
        .then((result) => {
          // console.log(result);
          console.log(result);
          this.$router.push("step-2");
          this.$store.dispatch("setData", result.data);
        })
        .catch((err) => {
          console.log(err);
        });

      // var step1Det = {
      //   title: this.form.title,
      //   firstname: this.form.fname,
      //   lastname: this.form.lname,
      //   citizenship: this.form.citizenship,
      //   dateOfBirth: this.form.DOB,
      // };

      // this.$store.dispatch("setData", step1Det);
      // console.log(this.$store.getters.getData);
      // this.$router.push("step-2");
    },
  },
};
</script>

<style lang="scss" scoped>
.step1 {
  width: 100%;
  height: 100%;

  .container {
    padding: 0 5vw;
    .form-group {
      font-size: 0.9rem !important;
    }
    label {
      font-size: 14px;
    }
    input {
      border-radius: 90px;
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
      padding: 0 20px;
    }

    select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 0 10px;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      outline: none;

      &::-ms-expand {
        display: none;
      }

      &:focus,
      &:active {
        border-color: transparent;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
          0 0 8px rgba(102, 175, 233, 0);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
          0 0 8px rgba(102, 175, 233, 0);
      }
    }

    .citySelect {
      width: 100%;
      border: 1px solid var(--select-border);
      border-radius: 0.25em;
      padding: 0 0.5em;
      cursor: pointer;
      line-height: 1.1;
      border-radius: 90px;
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
    }
  }

  .b-form-btn-label-control.form-control {
    border-radius: 90px;
    box-shadow: 0px 5px 9px #33333326;
    border: 1px solid #ebebeb;
  }

  .nextBtn {
    border-radius: 90px;
    box-shadow: 0px 5px 9px #33333326;
  }
}
</style>